import PropTypes from "prop-types";
import React from "react";
import bootstrap from "./bootstrap.module.css";

const CategoryPartner = ({ slug }) => {
    const ey = ['sztuczna-inteligencja-i-regulowanie-innowacji'];

    if(ey.includes(slug)) {
        return(
            <div className={`category-partner `+bootstrap.floatRight+` `+bootstrap.mb2+` `+bootstrap.mbLg0}>
                <span>Oficjalny partner kategorii</span>
                <img src="/assets/ey.svg" alt="Ey" />
            </div>
        );
    }  

    return null
}

CategoryPartner.propTypes = {
    slug: PropTypes.string,
};

export default CategoryPartner;
