import React from "react"
import bootstrap from "../components/bootstrap.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Tags from "./tags.js"
import Excerpt from "./excerpt.js"
import FormatDate from "../utils/format-date.js"
import PostThumbnail from "./thumbnail";
import Img from 'gatsby-image';

const TileSide = ({ node, className, id, hideThumbnail, hideExcerpt, showAuthor }) => {
    
    return (
        <div key={id} className={['card',bootstrap.h100,bootstrap.dMdFlex,className].join(' ')}>
            <div className={[bootstrap.colMd4,bootstrap.colLg5,bootstrap.order1,bootstrap.px0].join(' ')}><AniLink fade to={`/${node.post_name}/`} className={bootstrap.h100}><PostThumbnail image={node.mysqlImage} className={bootstrap.h100} /></AniLink></div>
            <div className={[bootstrap.p4].join(' ')}>       
                {showAuthor && <>
                    {node.author.mysqlImage && 
                        <div className={bootstrap.floatLeft + ' meta-image-sm ' + bootstrap.mr3}>
                        <Img fluid={node.author.mysqlImage.childImageSharp.fluid} />
                        </div>
                    }
                    <strong><AniLink fade to={"/redaktor/"+node.author.user_nicename+"/"}>{node.author.display_name}</AniLink></strong><br />
                </>}            
                <div className={bootstrap.small + ' meta ' + bootstrap.mb2}>{FormatDate(node.post_date)}</div>
                <AniLink fade to={`/${node.post_name}/`} className={bootstrap.mb1 + ( !hideThumbnail ? ' h4 ' + bootstrap.h4 : ' h3 ' + bootstrap.h3) + ' ' + bootstrap.dBlock}>{node.post_title}</AniLink>
                {!hideExcerpt && <Excerpt node={node} className={[bootstrap.py2,bootstrap.textMuted].join(' ')} />}
                <Tags tags={node.postterms} />
            </div>
        </div>
    )
  }

  
  export default TileSide