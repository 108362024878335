import React from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import bootstrap from "../components/bootstrap.module.css"
import CategoryPartner from '../components/category-partner.js'
import InfiniteScroll from 'react-infinite-scroll-component';
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import FilterPost from '../components/filter-post';
import TileSide from '../components/tile-side';

export default class Infinite extends React.Component {

	constructor(props) {
		super(props);
		
		this.posts = props.data.allMysqlPost.edges
		this.seo = props.data.seo
		this.perPage = this.props.pageContext.limit;
		this.skip = this.props.pageContext.skip;
		this.startPage = this.skip/this.perPage+1

		this.state = { 
			currentPage: props.pageContext.currentPage,
			currentList: this.posts.slice(0, this.perPage)
		};

		this.fetchData = this.fetchData.bind(this); 
	}

	render() {

		const pageContext = this.props.pageContext
		const data = this.props.data
		const extraTags = data.allTags.nodes
		const parentTags = data.parentTags.nodes
		const tags = data.tags.nodes

		const numPages = pageContext.numPages;
		const term = pageContext.term
		const perPage = this.perPage

		const { currentPage, currentList } = this.state

		const baseUrl = (term.parent_slug && term.slug !== 'legal' ? '/'+term.parent_slug : '') + '/' + term.slug + '/'

		const isFirst = currentPage === 1
		const isLast = currentPage === numPages
		const prevPage = currentPage - 1 === 1 ? '' : currentPage - 1
		const nextPage = currentPage + 1
	
		const pagesRange = []
		for(let i = currentPage-5 > 1 ? currentPage-5 : 1; i <= (currentPage+5 > numPages ? numPages : currentPage+5); i++) pagesRange.push(i)

		const link = [] 
		if(!isFirst) link.push({rel: 'prev', href: 'https://fintek.pl'+baseUrl+(prevPage > 1 ? prevPage+'/' : '')})
		if(!isLast) link.push({rel: 'next', href: 'https://fintek.pl'+baseUrl+nextPage+'/'})

		const title = (this.seo.title ? this.seo.title.replace(/%%term_title%%/gi, term.name).replace(/%%pagenumber%%/gi, currentPage) : term.name + ' - najnowsze informacje - Strona ' + currentPage)
		

		return (

			<Layout tags={[term]} extraTags={parentTags.length > 0 ? parentTags : (tags.length > 0 ? tags : extraTags)} className={'bg-light'}>
 
				<SEO title={title} description={this.seo.description ? this.seo.description : 'Sprawdź najnowsze informacje o %%term_title%% w Polsce i na świecie! U nas znajdziesz wszystko co chcesz wiedzieć o %%term_title%%! Informacje, aktualnosci, a nawet plotki!'.replace(/%%term_title%%/, term.name)} canonical={this.seo.canonical?this.seo.canonical:'https://fintek.pl'+baseUrl+(currentPage>1?currentPage+'/':'')} link={link} />  

				<Breadcrumbs tag={term} />

				<CategoryPartner slug={term.slug} />
				<h1>{term.name}</h1> 
				<hr className={bootstrap.mb4} />  
				{term.description && <div className={bootstrap.mb4} dangerouslySetInnerHTML={{ __html: term.description}}></div>}

				<InfiniteScroll
				dataLength={currentPage*perPage}
				next={this.fetchData}
				hasMore={(currentPage-this.startPage+1)*perPage<this.posts.length}
				loader={<div className={`spinner `+bootstrap.textCenter}><span></span><span></span><span></span><span></span></div>}
				>
					{currentList.map(({ node }, i) => (
						<FilterPost key={i} index={i} postname={node.post_name}>
							<div className={bootstrap.overflowHidden}>
								{(i > 0 && i%perPage === 0) && <div data-scrolling="top" data-url={baseUrl + ((i+this.skip)/perPage > 1 ? ((i+this.skip)/perPage) + '/' : '')}></div>}
								{(i > 0 && i%perPage === 0) && <div data-scrolling="bottom" data-url={baseUrl + ((i+this.skip)/perPage ? ((i+this.skip)/perPage+1) + '/' : '') }></div>}
								
								<TileSide node={node} className={bootstrap.mb4} showAuthor={(term.slug === 'felietony' || term.slug === 'podsumowania-i-prognozy-2024')} />
								
							</div>
						</FilterPost>
					))}   	

				</InfiniteScroll>

				{numPages > 1 && (
				<ul className={[bootstrap.pagination,bootstrap.py3,'paginator'].join(' ')}>
					{!isFirst && ( 
					<li key={prevPage} className={bootstrap.pageItem}><AniLink fade to={baseUrl+(prevPage >= 1 ? prevPage + '/' : '')} rel="prev" className={bootstrap.pageLink}>←</AniLink> </li>
					)}
					{pagesRange.map((i, _) => (
					<li key={`pagination-number${i}`} className={bootstrap.pageItem+(i===currentPage?' active '+bootstrap.active:'')}>
						<AniLink fade to={baseUrl+(i === 1 ? '' : i+'/')} className={bootstrap.pageLink}>{i}</AniLink> 
					</li>
					))}
					{!isLast && ( 
					<li key={nextPage} className={bootstrap.pageItem}>		
						<AniLink fade to={baseUrl+nextPage+'/'} rel="next" className={bootstrap.pageLink}>→</AniLink>
					</li>
					)}
				</ul>	
				)}


			</Layout>

		)


	}


	fetchData() {

		const { currentPage } = this.state

		this.setState({ 
			currentPage: currentPage+1,
			currentList: [...this.posts.slice(0, (currentPage+this.startPage)*this.perPage)]
		})
			
	}

	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);
	}
	  
	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
	}
	  
	topScroll = 0;

	trackScrolling = () => {
		const direction = window.scrollY > this.topScroll ? 'bottom' : 'top'
		const topZone = window.innerHeight/10
		const bottomZone = window.innerHeight*9/10
		const elements = [...document.querySelectorAll('[data-scrolling="'+direction+'"]')]
		
		this.topScroll = window.scrollY

		elements.map((e) => {
			if(e.getBoundingClientRect().top > topZone && e.getBoundingClientRect().top < bottomZone) {
				if(document.URL !== e.dataset.url) {
					window.history.pushState({}, "", e.dataset.url)
					if(typeof window.dataLayer !== 'undefined') window.dataLayer.push({ event: 'gatsby-route-change'});
 				}
			}
			return false
		})
	};

}

export const query = graphql`
query ($skip: Int!, $slug: String!, $parent_slug: String!, $termId: Int!) {
	allMysqlPost(limit: 20, skip: $skip, sort: {order: DESC, fields: post_date}, filter: {postterms: {elemMatch: {slug: {eq: $slug}}}, nofollow: {ne: 1}, indexables: {elemMatch: {is_robots_noindex: {nin: [1]}}}})  {
		totalCount
		edges {
			node {
				post_name
				post_title
				post_excerpt
				lead
				post_date
				postterms {
					name
					slug
					parent_slug
					parent_name
				}   			  
				mysqlImage {
					childImageSharp {
						fluid(maxWidth: 400, maxHeight: 200, cropFocus: CENTER) {
						...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				author {
					display_name
					user_nicename
					mysqlImage {
					  childImageSharp {
						  fluid(maxWidth: 100, maxHeight: 100, cropFocus: CENTER) {
							...GatsbyImageSharpFluid_withWebp 
						  }
						}
					  }
				  }      
			}            
		}
	}
	tags: allMysqlTerm(sort: {order: ASC, fields: name}, filter: { parent_slug: {eq: $slug} }) {
		nodes {
			slug
			name
			parent_slug
			parent_name
			description
		}
	}
	parentTags: allMysqlTerm(sort: {order: ASC, fields: name}, filter: { parent_slug: {eq: $parent_slug} }) {
		nodes {
			slug
			name
			parent_slug
			parent_name
			description
		}
	}	
	allTags: allMysqlTerm(sort: {order: ASC, fields: name}, filter: { slug: {ne: $slug} }) {
		nodes {
			slug
			name
			parent_slug
			parent_name
			description
		}
	}		
	seo: mysqlIndexable(object_type: {eq: "term"}, object_id: {eq: $termId}) {
		title
		description
		object_id
		object_type
		permalink
		breadcrumb_title
		canonical
	  }		
}
`
